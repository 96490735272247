function heap_identify() {

    let cmsCookie = Cookies.get('CmsCookie');

    if (cmsCookie != null && cmsCookie.length > 0) {

		var cmsCookieParsed = JSON.parse(decodeURIComponent(cmsCookie));

        if (cmsCookieParsed.AuthId) {
            heap.identify(cmsCookieParsed.AuthId);
        }
        
        if (cmsCookieParsed.Email) {
            heap.addUserProperties({ Email: cmsCookieParsed.Email });
        };

    }

}