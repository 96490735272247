// set 30 day cookies if query string parameter exists
var basVendor = getParameterByName('bas_vendor');
var mediacode = getParameterByName('mediacode');
var basDivision = getParameterByName('bas_division');
var basphone = getParameterByName('bas_phone');
var sfcid = getParameterByName('sfcid');
var gclid = getParameterByName('gclid');
var msclkid = getParameterByName('msclkid');
var utmsource = getParameterByName('utm_source');
var utmmedium = getParameterByName('utm_medium');
var utmcampaign = getParameterByName('utm_campaign');
var utmterm = getParameterByName('utm_term');
var utmcontent = getParameterByName('utm_content');
var campaignid = getParameterByName('campaignid');
var adgroupid = getParameterByName('adgroupid');
var keyword = getParameterByName('keyword');
var matchtype = getParameterByName('matchtype');
var network = getParameterByName('network');
var device = getParameterByName('device');
var extensionid = getParameterByName('extensionid');
var referrerid = getParameterByName('referrerid');

// cookieDomain variable is set in the header.php file to the value of
// a PHP constant so that we consitency across our JS/PHP cookies

if (basVendor) {
	Cookies.set('bas_vendor', basVendor, { domain: cookieDomain, expires: 30, path: '/' });
}
// set global JS variable to bas_vendor cookie value
var basVendorCookie = Cookies.get('bas_vendor');

if (mediacode) {
	Cookies.set('mediacode', mediacode, { domain: cookieDomain, expires: 30, path: '/' });
}
if (basDivision) {
	Cookies.set('bas_division', basDivision, { domain: cookieDomain, expires: 30, path: '/' });
}
if (basphone) {
	Cookies.set('bas_phone', basphone, { domain: cookieDomain, expires: 30, path: '/' });
}
if (sfcid) {
	Cookies.set('sfcid', sfcid, { domain: cookieDomain, expires: 30, path: '/' });
}
if (gclid) {
	Cookies.set('gclid', gclid, { domain: cookieDomain, expires: 30, path: '/' });
}
if (msclkid) {
	Cookies.set('msclkid', msclkid, { domain: cookieDomain, expires: 30, path: '/' });
}
if (utmsource) {
	Cookies.set('utm_source', utmsource, { domain: cookieDomain, expires: 30, path: '/' });
}
if (utmmedium) {
	Cookies.set('utm_medium', utmmedium, { domain: cookieDomain, expires: 30, path: '/' });
}
if (utmcampaign) {
	Cookies.set('utm_campaign', utmcampaign, { domain: cookieDomain, expires: 30, path: '/' });
}
if (utmterm) {
	Cookies.set('utm_term', utmterm, { domain: cookieDomain, expires: 30, path: '/' });
}
if (utmcontent) {
	Cookies.set('utm_content', utmcontent, { domain: cookieDomain, expires: 30, path: '/' });
}
if (campaignid) {
	Cookies.set('campaignid', campaignid, { domain: cookieDomain, expires: 30, path: '/' });
}
if (adgroupid) {
	Cookies.set('adgroupid', adgroupid, { domain: cookieDomain, expires: 30, path: '/' });
}
if (keyword) {
	Cookies.set('keyword', keyword, { domain: cookieDomain, expires: 30, path: '/' });
}
if (matchtype) {
	Cookies.set('matchtype', matchtype, { domain: cookieDomain, expires: 30, path: '/' });
}

if (network) {
	Cookies.set('network', network, { domain: cookieDomain, expires: 30, path: '/' });
}

if (device) {
	Cookies.set('device', device, { domain: cookieDomain, expires: 30, path: '/' });
}
if (extensionid) {
	Cookies.set('extensionid', extensionid, { domain: cookieDomain, expires: 30, path: '/' });
}
if (referrerid) {
	Cookies.set('referrerid', referrerid, { domain: cookieDomain, expires: 30, path: '/' });
}